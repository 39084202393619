@import '../../sass/abstracts/variables';

.modal {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: all 0.1s linear;
}

.modal.has-entered {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.modal__body {
    position: relative;
    margin: 10px;
    width: 90vw;

    @media only screen and ($device-tablet) {
        width: auto;
        margin: 30px;
    }
}

.underlay {
    transition: all 0.2s linear;
    background: rgba(0, 0, 0, 0);
}

.underlay.has-entered {
    background-color: rgba(0, 0, 0, 0.5);
}
