@import '../../sass/abstracts/variables';

.boxout {
    background-color: $color--white;
    border: 0 solid $color--grey-three;
    border-width: 1px 0;
    width: 100%;

    @media only screen and (min-width: 600px) {
        border-radius: 3px;
        border-width: 1px;
    }
}

.boxout__inner {
    padding: 22px;

    &--zeroBottomPadding {
        padding-bottom: 0;
    }
}

.boxout__heading {
    margin-bottom: 18px;
    font-family: $font--heading;
    font-size: 22px;
    color: $color--purple;
    text-transform: uppercase;
    display: inline-block;
}

.boxout__tooltip {
    line-height: 27px;
}
