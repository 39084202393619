@import '../../sass/abstracts/variables';

.back-button {
    position: relative;
    width: 100%;
    padding: 12px 0 12px 20px;
    margin: 0 0 0 -2px;
    font-family: $font--body;
    font-size: 14px;
    text-align: left;
    font-weight: $font-weight--bold;
    color: $color--green;
    border: 0;
    background: transparent;

    &:hover,
    &:focus {
        color: darken($color--green, 10%);
    }
}

.back-button__chevron {
    position: absolute;
    top: 50%;
    left: 2px;
    width: 12px;
    height: 12px;
    transform: translateY(-6px);
    transition: transform 0.2s;

    .back-button:hover &,
    .back-button:focus & {
        transform: translate(-2px, -6px);
    }
}
