@import '../../sass/abstracts/variables';

.copy-to-clipboard {
    position: relative;
}

.copy-to-clipboard__input-text {
    position: relative;
    width: 100%;
    padding: 12px 90px 12px 12px;
    font-size: 15px;
    border: 1px solid $color--grey-three;
    background: $color--white;
    transition: border 0.2s;
    color: transparent;
    text-shadow: 0 0 0 $color--grey;
    text-overflow: ellipsis;

    &:focus {
        outline: 0;
    }
}

.copy-to-clipboard__button-container {
    position: absolute;
    top: 1px;
    right: 1px;
}
.copy-to-clipboard__button {
    border: none;
    height: 43px;
    background: transparent;
}
.copy-to-clipboard__button-text {
    font-size: 13px;
    color: $color--green-two;
    padding-right: 12px;
    display: inline-block;
    vertical-align: middle;
}
.copy-to-clipboard__button-icon {
    font-size: 13px;
    color: $color--green-two;
    padding-right: 2px;
    display: inline-block;
    vertical-align: middle;

    & > svg {
        fill: $color--grey-five;
    }
}
