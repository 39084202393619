.quick-filters {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    & > div {
        width: 100%;
    }
}

.quick-filters--list-view {
    display: block;

    & > div {
        width: 100%;
        margin: 5px;
    }
}
