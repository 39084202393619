@import '../../sass/abstracts/variables';

.error-boundry {
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
        width: 32px;
        margin: 0 auto 80px;
        transform: scale(4);
    }

    h1 {
        text-align: center;
        max-width: 600px;
        padding: 0 20px;
    }

    a {
        font-family: $font--heading;
        font-weight: bold;
        font-size: 25px;
        color: $color--green;
    }
}
