@import '../../sass/abstracts/variables';

.icon-button {
    position: relative;
    width: 100%;
    padding: 15px 12px 14px 46px;
    font-family: $font--body;
    font-size: 14px;
    text-align: left;
    border-radius: 3px;
    color: $color--grey;
    background-color: $color--white;
    border: none;
    box-shadow: inset 0 0 0 2px $color--green-two;
    transition: box-shadow 0.2s;
    cursor: pointer;

    &:focus,
    &:hover {
        box-shadow: inset 0 0 0 2px $color--green;
    }
    &:disabled {
        opacity: 0.2;
        transition: opacity 2s;
        cursor: default;
    }
}

.icon-button--square {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
    padding: 24px 14px 24px;
    text-align: center;
    color: $color--green;
    font-weight: $font-weight--bold;
    background-color: transparent;
    box-shadow: inset 0 0 0 1px $color--grey-two;
    border-radius: 0;

    &:focus,
    &:hover {
        box-shadow: inset 0 0 0 2px $color--grey-six;
    }
    &:first-child {
        border-left: 0;
    }
    &:last-child {
        border-right: 0;
    }
}

.icon-button__icon {
    position: absolute;
    top: 50%;
    left: 18px;
    width: 18px;
    height: 18px;
    transform: translateY(-9px);

    & > svg {
        width: 100%;
        height: 100%;
        fill: $color--grey-seven;
    }

    .icon-button--square & {
        position: relative;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 20px;
        margin-bottom: 12px;
        transform: translateY(0);

        & > svg {
            fill: $color--grey-five;
        }
    }
}

.icon-button__text {
    .icon-button--square & {
        display: block;
        width: 100%;
    }
}
