@import '../../sass/abstracts/variables';

.facility-count {
    display: flex;
    align-items: center;
    font-size: 11px;
    margin: 0 10px 10px 0;
}

.facility-count__number {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    height: 16px;
    margin-right: 5px;
    text-align: center;
    font-weight: $font-weight--bold;
    color: $color--grey-five;
    line-height: 16px;
    z-index: 0;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 16px;
        background-color: $color--grey-two;
        border-radius: 8px;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}

.facility-count__label {
    color: $color--grey-five;
}

.facility-count--dark-theme {
    .facility-count__number {
        color: $color--grey;
    }

    .facility-count__number::before {
        background-color: $color--grey-six;
    }

    .facility-count__label {
        color: $color--grey-three;
    }
}
