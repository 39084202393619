@import '../../sass/abstracts/variables';

.delete-button {
    position: relative;
    width: 100%;
    padding: 12px 0 12px 26px;
    margin: 12px 0 0 -2px;
    font-family: $font--body;
    font-size: 14px;
    text-align: left;
    color: $color--red2;
    border: 0;
    background: transparent;

    &:hover,
    &:focus {
        color: darken($color--red2, 10%);
    }
}

.delete-button__icon {
    position: absolute;
    top: 50%;
    left: 2px;
    width: 16px;
    height: 16px;
    opacity: 0.8;
    transform: translateY(-7px);
    fill: $color--grey;
}
