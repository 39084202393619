@import '../../sass/abstracts/variables';

.input-checkbox {
    position: relative;

    & ~ & {
        margin-top: 10px;
    }
}

.input-checkbox__mask {
    display: block;
    padding: 15px 40px 14px 13px;
    font-size: 13px;
    border-radius: 3px;
    background: transparent;
    color: $color--grey;
    box-shadow: inset 0 0 0 1px $color--grey-three;
    transition: box-shadow 0.2s;
    cursor: pointer;

    .disabled & {
        cursor: default;
    }

    &:focus,
    &:hover,
    &--selected {
        box-shadow: inset 0 0 0 2px $color--green;
    }

    &:focus {
        box-shadow: inset 0 0 0 3px $color--green;
    }
}

.input-checkbox__input {
    &:focus {
        & + .input-checkbox__mask {
            box-shadow: inset 0 0 0 3px $color--green;
        }
    }
}

.input-checkbox__icon {
    position: absolute;
    top: 11px;
    right: 15px;
    width: 20px;
    height: 20px;
    fill: $color--green;
}

.input-checkbox--is-tick {
    .input-checkbox__icon {
        top: 0px;
        left: 0px;
        width: 20px;
        height: 20px;
        fill: none;
        box-shadow: inset 0 0 0 1px $color--grey-three;
        border-radius: 3px;
    }

    .input-checkbox__input {
        &:checked,
        &:focus {
            & + .input-checkbox__mask {
                box-shadow: none;

                .input-checkbox__icon {
                    box-shadow: inset 0 0 0 1px $color--green;
                }
            }
        }

        &:checked {
            & + .input-checkbox__mask {
                .input-checkbox__icon {
                    fill: $color--green;
                }
            }
        }
    }

    .input-checkbox__mask {
        padding: 5px 14px 5px 30px;
        box-shadow: none;

        &:hover {
            box-shadow: none;

            .input-checkbox__icon {
                box-shadow: inset 0 0 0 1px $color--green;
            }
        }

        &--selected {
            .input-checkbox__icon {
                fill: $color--green;
                box-shadow: inset 0 0 0 1px $color--green;
            }
        }
    }
}
