@import '../../sass/abstracts/variables';

.heading {
    margin: 0 0 20px;
    font-family: $font--heading;
    font-size: 28px;
    line-height: 32px;

    @media only screen and ($device-tablet) {
        font-size: 33px;
        line-height: 40px;
        margin: 0 0 33px;
    }
}

.heading--sub {
    margin: 0 0 18px;
    font-family: $font--body;
    font-size: 15px;
    line-height: 19px;
    font-weight: $font-weight--bold;
}
