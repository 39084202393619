@import '../../sass/abstracts/variables';

.inline-link {
    color: $color--green;
    text-decoration: none;
    font-weight: $font-weight--bold;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
