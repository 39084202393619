@import '../../sass/abstracts/variables';

.selector {
    position: relative;

    @media only screen and ($device-tablet) {
        width: $modal-width;
    }

    p {
        margin-bottom: 20px;
        line-height: 1.6;
    }
}

.selector__row {
    margin-bottom: 30px;

    &:not([class*='--full']):last-of-type {
        margin-bottom: 0;
    }
}

.selector__row--full {
    background: $color--grey-four;
    padding: 30px $modal-padding--mobile;
    margin: 0 -#{$modal-padding--mobile} 30px;

    @media only screen and ($device-tablet) {
        padding: 30px $modal-padding--tablet;
        margin: 0 -#{$modal-padding--tablet} 30px;
    }
}

.selector__row--columns {
    @media only screen and ($device-tablet) {
        display: flex;
        margin: 0 -10px 0 -10px;
    }
}

.selector__row--padded {
    padding-bottom: 30px;
}

.selector__column {
    margin-bottom: 20px;

    @media only screen and ($device-tablet) {
        width: 50%;
        padding: 0 10px;
        margin-bottom: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.selector__heading {
    width: calc(100% - 130px);
}

.selector__button {
    width: 100%;
    margin-bottom: 10px;

    & + &:not([class*='--rounded']) {
        margin-bottom: 0;
    }

    @media only screen and ($button--large) {
        width: auto;
        margin: 0;
    }
}
