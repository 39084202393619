@import '../../sass/abstracts/variables';

.alert {
    color: $color--white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
    font-family: Arial;
    box-sizing: border-box;
    line-height: 1.3;
}

.info {
    background-color: rgba($color--green-two, 0.95);
}
.success {
    background-color: rgba($color--green-two, 0.95);
}
.error {
    background-color: rgba($color--red, 0.95);
}
.warning {
    background-color: rgba($color--orange, 0.95);
}

.button {
    margin-left: 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #ffffff;
}
