@import '../../sass/abstracts/variables';

.dropdown-nav__button {
    position: relative;
    padding: 11px;
    border-radius: 3px 3px 0px 0px;
    border: 1px solid transparent;
    background: none;
    opacity: 1;
    transition: opacity 0.3s;
    z-index: 1;

    svg {
        opacity: 1;
        transition: opacity 0.3s;
    }

    &:hover,
    &:focus {
        svg {
            opacity: 0.7;
        }
    }
}

.dropdown-nav__button--open {
    background-color: $color--white;
    border: 1px solid $color--grey-three;
    border-bottom-color: $color--white;
}

.dropdown-nav__menu {
    position: absolute;
    display: none;
    margin-top: -1px;
    width: 180px;
    padding: 20px;
    font-size: 13px;
    background-color: $color--white;
    border: 1px solid $color--grey-three;
    border-radius: 0 3px 3px 3px;
    box-shadow: 2px 2px 5px rgba($color--grey, 0.2);
    z-index: 0;

    a {
        min-width: 160px;
        display: inline-block;
    }

    li {
        line-height: 1.2;
    }

    li:not(:last-child) {
        margin-bottom: 10px;
    }
}

.dropdown-nav__menu--open {
    display: block;
}

.dropdown-nav__menu--left {
    border-radius: 3px 0 3px 3px;
    right: 0;
}
