@import '../../sass/abstracts/variables';

.menu-button {
    $root: &;

    align-items: center;
    appearance: none;
    background: none;
    border: 0;
    display: flex;
    flex-direction: row;
    padding: 11px;

    span {
        color: $color--black-two;
        font-family: $font--heading;
        font-size: 18px;
        font-weight: 600;
        margin-left: 8px;
    }

    svg {
        height: 16px;
        width: 16px;

        // Override cross icon size so it's consistent with the filter icon
        &#{$root}__cross {
            transform-origin: center;
            transform: scale(1.4);
        }
    }

}
