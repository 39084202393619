@import '../../sass/abstracts/variables';

.detail {
    padding-bottom: 3px;
    font-size: 14px;
    line-height: 18px;
    color: $color--black;
    border-bottom: 1px dashed $color--grey-three;
}

.detail__label {
    display: block;
    font-weight: $font-weight--bold;
    margin: 0 0 5px;
}

.detail__value {
    color: $color--grey-five;
    word-break: break-word;
}
