// Colors
$color--red: #fd2415;
$color--red2: #fc6969;

$color--white: #ffffff;

$color-blue: #edf5f8;

$color--green: #00713c;
$color--green-two: #009a3a;
$color--green-three: #5ab947;
$color--green-four: #22d02b; // Green from footballfoundation.org.uk

$color--black: #000000;
$color--black-two: #1e272b;
$color--grey: #1c1c1c;
$color--grey-two: #d8d8d8;
$color--grey-three: #b8bfc1;
$color--grey-four: #f9f9f9;
$color--grey-five: #424242;
$color--grey-six: #aaaaaa;
$color--grey-seven: #777777;
$color--grey-eight: #f3f3f3;

$color--purple: #54448c;
$color--purple-two: #3c3064;
$color--purple-three: #6e5fa0;

$color--orange: #ff7210f2;

// Fonts
$font--body: 'Montserrat', sans-serif;
$font--heading: 'Barlow Condensed', sans-serif;

$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--bold: 600;

$device-desktop: 'min-width: 1024px';
$device-desktop--max-width: 'max-width: 1024px';
$device-tablet: 'min-width: 768px';
$device-tablet--max-width: 'max-width: 768px';
$device-mobile--small: 'min-width: 360px';
$device-mobile--medium: 'min-width: 600px';

$button--large: 'min-width: 728px';
$site-header-height: 75px;
$landing-header-height: 115px;
$sidebar-width: 380px;
$modal-width: 700px;

$modal-padding--mobile: 21px;
$modal-padding--tablet: 42px;

$z-index--site-header-dropdown: 99;
$z-index--modal: 1050; // not used directly, probably the default for AriaModal
$z-index--message: 1100; // not used from css, defined in App.js only
