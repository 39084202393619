@import '../../sass/abstracts/variables';

$font-size: 13px;

.map-key {
    border-radius: 3px;
}

.map-key__header {
    background-color: $color--grey-four;
    border-bottom: 1px solid $color--grey-two;
    border-radius: 3px 3px 0 0;
}

.map-key__button {
    position: relative;
    padding: 20px;
    font-size: 15px;
    font-weight: $font-weight--bold;
    padding-right: 45px;
    cursor: pointer;

    &::before,
    &::after {
        position: absolute;
        content: '';
        height: 1px;
        width: 15px;
        background-color: $color--green;
    }

    &::before {
        top: 27px;
        right: 20px;
    }

    &::after {
        display: block;
        top: 27px;
        right: 25px;
        transform: rotate(90deg) translateY(-5px);
    }

    &[aria-expanded='true'] {
        &::after {
            display: none;
        }
    }
}

.map-key__body {
    padding: 0 20px;
    color: $color--grey-five;
    background-color: $color--white;
    border-radius: 0 0 3px 3px;
}

.map-key__row {
    padding: 20px 0;

    & + & {
        border-top: 1px dashed $color--grey-three;
    }
}

.map-key__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $font-size;
    min-width: 200px;

    &--subheading {
        font-weight: 600;
    }

    & + & {
        margin-top: 18px;
    }

    // add eye icon to toggle each overlay bracket (but not if taking a screenshot)
    .map-key__row.overlay:not(.screenshot) &:not(.map-key__item--subheading) {
        &:hover {
            cursor: pointer;
        }

        &.hidden {
            text-decoration: line-through;
        }
    }

    // increase contrast when taking a screenshot
    .screenshot & {
        color: #000;
    }
}

.map-key__item-label {
    margin-left: auto;
    padding-right: 7px;
    opacity: 0.6;
    font-family: Helvetica;
    font-size: 10px;
    color: #1c1c1c;
    letter-spacing: 0;
}

.map-key__circle {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    margin: 0 5px 0 10px;
}

.map-key__eye {
    width: 17px;
    margin-right: 10px;
}

.map-key__square {
    stroke-width: 1px;
    stroke: $color--grey-two;
    width: 14px;
    height: 14px;
    margin-left: auto; // this is so that things work out for the overlay brackets with justify-content: space-between;
}

.map-key__icon {
    flex-shrink: 0;
    width: 20px;
    height: 24px;
    margin-left: 10px;
}

// Reset styles for the <button> containg a <CrossIcon>
.map-key__custom-geojson-remove {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    background: none;
}

// LFFP-specific priority projects appear under the custom geosjson key(s)
.map-key__custom-geojson-item {
    display: inline-block;
    margin-bottom: 0.4em;

    &:last-child {
        margin-bottom: 0;
    }

    // icon
    span:first-child {
        $size: 22px;
        $font-size: $size / 2 + 1px;
        $text-color: #fff;
        color: $text-color;
        display: inline-block;
        border-radius: 50%;
        width: $size;
        height: $size;
        line-height: $size;
        margin-right: 7px;
        margin-bottom: 5px;
        font-size: $font-size;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        vertical-align: top;
        cursor: default;
    }

    // label
    span:last-child {
        font-size: $font-size;
        line-height: 1.3;
        display: inline-block;
    }
}
