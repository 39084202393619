@import '../../sass/abstracts/variables';

.results-list {
    height: auto;
}

.results-list__heading {
    color: $color--grey-five;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 14px;
}

.results-list__site-card {
    margin-bottom: 10px;
}
