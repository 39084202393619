@import '../../sass/abstracts/variables';

.category-list {
    width: 100%;
}

.category-list--columns {
    @media only screen and ($device-tablet) {
        column-count: 2;
        column-gap: 30px;
    }

    .category-list__item {
        display: block;
    }
}

.category-list__item {
    display: flex;
    align-items: center;
    border-top: 1px dotted $color--grey-three;
}
