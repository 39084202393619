@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/mixins';

.site-information-facilities__tabs-container {
    margin: 0 -23px;
}

.site-information-facilities__detail-grid {
    padding: 10px 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @media only screen and ($device-tablet) {
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and ($device-desktop) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.site-information-facilities__detail {
    border-bottom: 1px dashed $color--grey-three;

    @media only screen and ($device-tablet--max-width) {
        @include last-grid-row(1) {
            border-bottom: none;
        }
    }

    @media only screen and ($device-tablet) and ($device-desktop--max-width) {
        @include last-grid-row(2) {
            border-bottom: none;
        }
    }

    @media only screen and ($device-desktop) {
        @include last-grid-row(3) {
            border-bottom: none;
        }
    }
}

.site-information-facilities__summary-table_arrow {
    position: absolute;
    right: 0;
    top: 0;
    color: black;
    height: 100%;
    width: 40px;
    width: 150px;
    align-items: center;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) -50%,
        #fff 100%
    );
    padding-right: 20px;
    transition: opacity 0.25s ease;
    opacity: 1;

    &--hide {
        opacity: 0;
    }
}
.site-information-facilities__summary-table_arrow::before {
    position: absolute;
    content: '';
    width: 0.4em;
    height: 0.4em;
    border-right: 0.2em solid $color--green-two;
    border-top: 0.2em solid $color--green-two;
    transform: rotate(45deg);
    top: calc(50% - 0.3em);
    font-size: 300%;
    right: 30px;
    opacity: 0.6;
}

.site-information-facilities__summary-table {
    position: relative;
    overflow-x: auto;
    padding-bottom: 3px;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    margin-bottom: 30px;
    color: $color--black;

    table {
        table-layout: fixed;
        width: 100%;
        text-align: center;
        border-spacing: 1px;
        border-collapse: separate;
    }

    tr {
        display: block;
    }

    th {
        width: 200px;
        min-width: 110px;
        font-weight: $font-weight--bold;
        padding: 10px 10px 10px 0;

        &:first-child {
            min-width: 140px;
            width: 300px;
            text-align: left;
        }
    }

    td {
        width: 200px;
        min-width: 110px;
        color: $color--grey-five;
        word-break: break-word;
        padding: 10px 10px 10px 0;
        border-top: 1px dashed #b8bfc1;

        &:first-child {
            min-width: 140px;
            width: 300px;
            text-align: left;
        }
    }
}

.site-information-facilities__heading {
    color: $color--grey-three;
    font-size: 18px;
    font-family: $font--heading;
    background-color: white;
    letter-spacing: 0.6px;
    padding: 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid $color--grey-three;
    text-transform: uppercase;
}

.site-information-facilities__empty {
    padding-bottom: 30px;
}
