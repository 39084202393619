@import '../../sass/abstracts/variables';

.site-header {
    position: absolute;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    top: 0;
    left: 0;
    width: 100%;
    height: $site-header-height;
    background-color: $color--white;
    z-index: 4;
    padding: 20px;

    &--has-hamburger {
        padding-left: 9px;
    }

    &--fullwidth {
        width: 100% !important;
        padding: 0;
        height: $landing-header-height;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 0;

        // Reset existing overriding styles
        .site-header__brand {
            padding: 20px;
            // keep it centred but don't let it expand horizontally:
            align-self: center;
            justify-content: center;
            position: absolute;
            right: 0;
            left: 0;
            margin: auto;
            width: 200px;
        }
        .site-header__dropdown-nav {
            justify-self: flex-end;
            position: relative;
            top: 10px;
        }
    }

    @media only screen and ($device-desktop) {
        padding-left: 20px;
        width: $sidebar-width;
    }
}

.site-header__partners {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    margin: -20px;
    padding: 50px 20px;

    img {
        height: 50px;
        margin-right: 15px;
    }

    @media only screen and ($device-desktop) {
        opacity: 1;
    }
}

.site-header__brand {
    display: flex;
    align-items: flex-end;
    align-self: center;
    color: $color--black;
    text-decoration: none;
    text-transform: uppercase;

    @media only screen and ($device-desktop) {
        align-self: flex-start;
    }
}

.site-header__menu-button {
    left: 5px;
    position: absolute;
    top: 20px;
}

.site-header__brand-icon {
    margin-right: 6px;
    fill: $color--green-three;
    transition: fill 0.2s;

    .site-header__brand:hover & {
        fill: $color--green-two;
    }
}

.site-header__brand-name {
    font-size: 25px;
    line-height: 20px;
    font-family: $font--heading;
}

.site-header__dropdown-nav {
    position: absolute;
    top: 20px;
    right: 5px;
    z-index: $z-index--site-header-dropdown;
}
.site-header__dropdown-nav_break {
    border-top: 1px solid $color--grey-two;
    margin-right: -20px;
    margin-left: -20px;
    padding: 0 20px;
    margin: 18px -20px;
}
.site-header__dropdown-nav__icon {
    display: flex;
    align-items: center;

    span {
        display: block;
        height: 18px;
        font-size: 18px;
        font-weight: 600;
        color: $color--black-two;
        font-family: $font--heading;
        margin-bottom: 5px;
    }

    svg {
        margin-bottom: 5px;
    }
}
