@import '../../sass/abstracts/variables';

.share-map {
    position: relative;

    @media only screen and ($device-tablet) {
        width: 560px;
    }

    p {
        margin-bottom: 20px;
        line-height: 1.6;
    }
}

.share-map__description {
    width: 480px;
    line-height: 1.6;
    margin-bottom: 20px;
}
.share-map__row {
    background: $color--grey-four;
    padding: 30px $modal-padding--mobile 60px $modal-padding--mobile;
    margin: 0 (-$modal-padding--mobile) -24px;

    @media only screen and ($device-tablet) {
        padding: 30px $modal-padding--tablet 60px $modal-padding--tablet;
        margin: 0 (-$modal-padding--tablet) -24px;
    }
}
