@import '../../sass/abstracts/variables';

.remove-button {
    position: relative;
    padding: 0;
    width: 24px;
    height: 42px;
    border: 0;
    background: transparent;

    &:hover,
    &:focus {
        .remove-button__icon {
            fill: darken($color--red, 10%);
        }
    }
}

.remove-button__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    transform: translate(-12px, -12px);
    fill: $color--red;
}
