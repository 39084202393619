
.filter-group-edit {
    display: flex;
    align-items: center;
}

.filter-group-edit__label {
    font-size: 15px;
}

.filter-group-edit__circle {
    width: 12px;
    height: 12px;
    margin-left: 5px;
}
