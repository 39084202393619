@import '../../sass/abstracts/variables';

.search-card {
    display: block;
    text-decoration: none;
    position: relative;
    padding: 11px;
    background: $color--white;
    border: 1px solid $color--grey-two;
    color: $color--grey-five;
    border-radius: 3px;

    &:hover,
    &:focus {
        cursor: pointer;
        border: 1px solid $color--grey-three;
    }
}

.search-card__header {
    position: relative;
    margin-bottom: 10px;
}

.search-card__name {
    display: block;
    margin-bottom: 3px;
    font-size: 14px;
    width: calc(100% - 70px);
    line-height: 18px;
    color: $color--green-two;
    font-weight: $font-weight--bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.search-card__location {
    font-size: 11px;
    line-height: 14px;
}

.search-card__buttons {
    position: absolute;
    top: 10px;
    right: 10px;
}

.search-card__button {
    position: relative;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    background: transparent;

    svg {
        width: 100%;
        height: 100%;
    }

    &--share svg {
        padding: 0 3px;
    }

    & + & {
        margin-left: 15px;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: -7px;
            width: 1px;
            height: 100%;
            background: $color--grey-two;
        }
    }
}

.search-card__details {
    padding: 11px 11px 0;
    background-color: $color--grey-four;
}

.search-card__facilites {
    display: flex;
    flex-wrap: wrap;
}

.search-card__facility-count {
    margin: 0 10px 10px 0;
}

.search-card__stats {
    font-size: 11px;
}
