@import '../../sass/abstracts/variables';

.tooltip__text-box {
    display: inline-block;
    margin-left: 10px;
    width: 14px;
    height: 14px;
    position: absolute;

    fill: $color--green-two;

    &:hover {
        fill: $color--green;
    }
}
