@import '../../sass/abstracts/variables';

.overlay-group {
    position: relative;
    padding: 15px 15px 5px;
    background-color: $color--white;
    border: 1px solid $color--grey-two;
    border-radius: 3px;
}

.overlay-group__label {
    display: block;
    margin: 0 0 11px;
    width: calc(100% - 40px);
    font-size: 15px;
}

.overlay-group__edit {
    position: absolute;
    top: 15px;
    right: 15px;
}

.overlay-group__input {
    margin-bottom: 10px;
}
