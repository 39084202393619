@import '../../sass/abstracts/variables';

.composed-input {
    width: 100%;

    & + & {
        margin-top: 30px;
    }
}

.composed-input__heading {
    display: flex;
    &__required {
        color: $color--red;
        padding: 2px 4px;
    }
}

.composed-input__fields {
    @media only screen and ($device-tablet) {
        display: flex;
    }
}

.composed-input__operators {
    width: 100%;

    & ~ .composed-input__input-wrapper {
        margin: 20px 0 0 0;

        @media only screen and ($device-tablet) {
            margin: 0 0 0 5px;
        }
    }
}

.composed-input__input-wrapper {
    width: 100%;
}
