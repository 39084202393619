@import '../../sass/abstracts/variables';

.filter-group-form {
    position: relative;

    @media only screen and ($device-tablet) {
        width: $modal-width;
    }

    p {
        margin-bottom: 20px;
        line-height: 1.6;
    }
}

.filter-group-form__button {
    width: 100%;
    margin-bottom: 10px;

    & + &:not([class*='--rounded']) {
        margin-bottom: 0;
    }

    @media only screen and ($button--large) {
        width: auto;
        margin: 0;
    }
}
