@import '../../sass/abstracts/variables';

.input-radio {
    & ~ & {
        margin-top: 10px;
    }
}

.input-radio__mask {
    display: block;
    padding: 15px 13px 14px;
    font-size: 13px;
    border-radius: 3px;
    background: transparent;
    color: $color--grey;
    box-shadow: inset 0 0 0 1px $color--grey-three;
    transition: all 0.2s;
    cursor: pointer;

    &:focus,
    &:hover,
    &--selected {
        background: $color--white;
        box-shadow: inset 0 0 0 2px $color--green;
    }

    &:focus {
        box-shadow: inset 0 0 0 3px $color--green;
    }
}

.input-radio__input {
    &:focus {
        & + .input-radio__mask {
            background: $color--white;
            box-shadow: inset 0 0 0 3px $color--green;
        }
    }
}

