@import '../../sass/abstracts/variables';

.stat__label {
    display: block;
    margin: 0 0 10px;
    font-family: $font--body;
    font-size: 15px;
    line-height: 19px;
    font-weight: $font-weight--bold;
}

.stat__value {
    font-family: $font--body;
    font-size: 28px;
    line-height: 32px;
    font-weight: $font-weight--light;

    @media only screen and ($device-tablet) {
        font-size: 32px;
        line-height: 39px;
    }
}
