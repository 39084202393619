@import '../../sass/abstracts/variables';
@mixin ff-pattern($color: rgba(#428F42, 0.1)) {
    background: transparent;
    background-image: radial-gradient($color 25%,transparent 0),radial-gradient($color 25%,transparent 0);
    background-repeat: repeat;
    background-position: 0 0,.5rem .5rem;
    -webkit-background-size: 1rem 1rem;
    background-size: 1rem 1rem;
}

.browse-sites {
    width: 100%;
    min-height: 100vh;
    min-height: var(--viewport-height, 100vh);
    height: 100%;
    background: white;
}

.browse-sites__header {
    position: relative;
    width: 100%;
    height: 250px;
    background-image: url('/images/header-bg.png');
    background-position: center 45%;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and ($device-tablet) {
        height: 450px;
    }
}

.browse-sites__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 5;
    padding-bottom: 80px;
}

.browse-sites__content__search {
    width: 100%;
    max-width: 700px;
    min-height: 218px;
    background-image: url('/images/header-shard.svg');
    background-repeat: no-repeat;
    background-size: contain;

    margin: auto;
    margin-top: -100px;
    box-sizing: border-box;
    padding-top: 80px;
    padding-left: 40px;
    padding-right: 40px;

    @media only screen and ($device-tablet) {
        margin-top: -180px;
        padding-top: 120px;
        padding-left: 75px;
        padding-right: 75px;
    }
}

.browse-sites__content__title {
    color: $color--black-two;
    font-size: 30px;
    line-height: 46px;

    margin: 0 auto;
    padding: 0 10px;
    text-align: center;

    @media only screen and ($device-tablet) {
        margin-top: 20px;
        font-size: 42px;
        max-width: 700px;
        padding: 0;
    }
}

.browse-sites__content__features {
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 60px;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    @media only screen and ($device-desktop) {
        flex-direction: row;
        justify-content: center;
    }
}

.browse-sites__content__feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 75%;
    margin: 0 auto;

    h1 {
        font-size: 64px;
        line-height: 46px;
        color: $color--black-two;
        margin-top: 30px;
        margin-bottom: 50px;
        text-shadow: 0 15px #CFFFC9;
    }

    span {
        text-align: center;
        font-size: 18px;
        max-width: 100%;
        line-height: 26px;
    }

    width: 33.33%;
    padding: 30px 0 25px;
    @media only screen and ($device-desktop) {
        padding: 0 25px;
    }
}

.browse-sites__branding {
    position: relative;
    z-index: 0;
    background: #00713C;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 50px;
    padding-bottom: 100px;

    &__logo {
        display: block;
        width: 220px;
        height: 220px;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }

    &__desc {
        font-size: 14px;
        line-height: 24px;
        color: white;
        text-align: center;
        max-width: 90%;
        margin: 0 auto;
        margin-top: 18px;

        @media only screen and ($device-tablet) {
            max-width: 570px;
        }
    }

    &__overlay {
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;

        // Generate circle background
        @include ff-pattern;

        // Prebuilt image, from FF site
        @media only screen and ($device-tablet) {
            background-image: url(/images/footer-bg.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

.browse-sites__content__shard_left {
    z-index: -1;
    height: 180px;
    width: 80px;
    top: 400px;
    left: 0;
    position: absolute;
    background-image: url('/images/left-shard-fallback.png');
    background-size: contain;

    @media only screen and ($device-desktop) {
        height: 240px;
        width: 160px;
        top: 55px;
    }

    $shape: polygon(0% 100%, 0% 5%, 100% 0%);
    @supports (clip-path: $shape) {
        clip-path: $shape;
        background: #F0FEEB;
    }
}

.browse-sites__content__shard_right {
    z-index: -1;
    height: 180px;
    width: 80px;
    top: 650px;
    right: 0;
    opacity: 0.5;
    position: absolute;
    background: url('/images/right-shard-fallback.png');

    @media only screen and ($device-desktop) {
        height: 240px;
        width: 160px;
        top: 200px;
        opacity: 0.8;
    }

    $shape: polygon(100% 100%, 0% 25%, 100% 0%);
    @supports (clip-path: $shape) {
        clip-path: $shape;
        @include ff-pattern(#AFECA8);
    }
}

.browse-sites__footer {
    min-height: 80px;
    background-color: #1D1D1D;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;

    @media only screen and ($device-tablet) {
        flex-direction: row;
        padding: 0 60px;
    }
}

.browse-sites__footer__links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
        position: relative;
        color: white;
        font-size: 12px;
        color: #979797;
        padding-right: 15px;
        margin-right: 15px;
        padding-bottom: 15px;

        a {
            color: #979797;
            text-decoration: none;
        }

        @media only screen and ($device-desktop) {
            padding-bottom: 0;
            &:after {
                position: absolute;
                right: 0;
                top: 0px;
                content: '';
                height: 20px;
                width: 1px;
                border-left: 1px solid #323232;
            }
            &:last-of-type::after {
                border: none;
            }
        }
    }
}

.browse-sites__footer__copyright {
    font-size: 12px;
    line-height: 18px;
    color: #979797;
    margin-top: 10px;
    @media only screen and ($device-desktop) {
        margin: 0;
    }
}
