.input-select {
    min-width: 160px;

    // Increase specificity over React plugin styles
    & .input-select__clear {
        padding-top: 0;
        padding-bottom: 0;
    }

    & .input-select__search {
        width: 20px;
        height: 24px;
        padding: 0;
    }
}

