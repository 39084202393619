@import '../../sass/abstracts/variables';

.site-card-icon {
    display: flex;
    align-items: center;
    font-size: 11px;
    margin: 0 10px 10px 0;
}

.icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.check {
    fill: $color--green-two;
}

.cross {
    fill: $color--red;
}

.dark-theme {
    color: $color--grey-three;
}
