@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/mixins';

.site-information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.site-information__header {
    position: relative;
    width: 100%;
    margin-top: 0; // Heading height
    pointer-events: none;

    @media only screen and ($device-tablet) {
        max-width: 90%;
        margin-top: -130px; // Heading height
    }
}

.site-information__header-inner {
    padding: 23px 30px 0 30px;
    background: $color--grey-eight;
    max-width: 100%;
    pointer-events: all;

    @media only screen and ($device-tablet) {
        display: inline-block;
    }
}

.site-information__inner {
    min-height: 100%;
    width: 100%;
    background: $color--grey-eight;
    padding: 30px 0;

    @media only screen and ($device-mobile--medium) {
        padding: 30px;
    }
}

.site-information__header-icon {
    float: left;
    height: 30px;
    width: 30px;
    margin: 8px 8px 0 0;
}

.site-information__header-heading {
    margin: 0 0 15px 0;
    font-size: 28px;

    &.non-operational {
        color: #8a8a8a;
    }

    @media only screen and ($device-mobile--medium) {
        font-size: 42px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 50px;
        white-space: nowrap;
    }
}

.site-information__back {
    display: flex;
    align-items: center;
    margin: 0 0 7px 0;
    padding: 0;
    font-family: $font--body;
    font-size: 15px;
    color: $color--grey-five;
    border: none;
    background-color: transparent;

    &:hover,
    .site-information__back &:focus {
        fill: $color--green-two;
        opacity: 1;
    }
}

.site-information__back-icon {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    fill: $color--green-two;
    opacity: 0.5;
    transition: opacity 0.3s;

    .site-information__back:hover &,
    .site-information__back:focus & {
        fill: $color--green-two;
        opacity: 1;
    }
}

.site-information__row {
    @media only screen and ($device-tablet) {
        display: flex;
        margin: 0 -10px 20px;
    }
}

.site-information__boxout {
    margin: 0 0 20px;
    width: 100%;

    @media only screen and ($device-tablet) {
        margin: 0 10px;
    }
}

.site-information__detail-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding: 10px 0;

    @media only screen and ($device-tablet) {
        grid-template-columns: 1fr 1fr;
    }
}

.site-information__detail {
    @media only screen and ($device-tablet--max-width) {
        @include last-grid-row(1) {
            border-bottom: none;
        }
    }

    @media only screen and ($device-tablet) {
        @include last-grid-row(2) {
            border-bottom: none;
        }
    }
}

.site-information__detail--full {
    width: calc(100% - 20px);
}

.site-information__detail--space {
    margin-bottom: 30px;
}

.site-information__highlight {
    margin: 0 0 20px 0;
    padding: 10px 13px;
    font-size: 15px;
    line-height: 18px;
    color: $color--grey-five;
    background-color: $color-blue;

    strong {
        font-weight: $font-weight--bold;
    }
}

.site-information__highlight--grid {
    display: flex;
    flex-wrap: wrap;
    padding: 9px 0 0;
}

.site-information__highlight-icon {
    width: 100%;
    margin: 0 10px 10px;

    @media only screen and ($device-tablet) {
        width: calc(50% - 20px);
    }
}

.site-information__grants {
    @media only screen and ($device-tablet) {
        display: flex;
        margin: 0 -20px;
        flex-wrap: wrap;
    }

    @media only screen and ($device-desktop) {
        flex-wrap: nowrap;
    }
}

.site-information__grant {
    width: 100%;
    margin-bottom: 20px;

    &.site-information__grant--disabled {
        color: #aaa;
    }

    @media only screen and ($device-tablet) {
        width: calc(50% - 40px);
        padding: 10px;
        margin: 10px;
    }

    @media only screen and ($device-desktop) {
        width: 100%;
        border-right: 1px dashed $color--grey-three;

        &:last-child {
            border-right: none;
        }
    }
}

.site-information__statistics {
    width: 100%;

    @media only screen and ($device-mobile--medium) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0;
    }

    .site-information__statistic {
        @media only screen and ($device-mobile--medium) {
            width: calc(50% - 20px);
            margin: 10px;
        }

        @media only screen and ($device-tablet) {
            width: calc(25% - 20px);
            margin: 10px;
        }
    }
}

.site-information__statistic-value {
    margin-top: 20px;
}
