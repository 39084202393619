.input-range {
    display: flex;
    margin: 0 -5px;
}

.input-range__heading {
    margin: 5px 0 5px;
}

.input-range__field {
    margin: 0 5px;
    width: 50%;
}
