@import '../../sass/abstracts/variables';

.icon-label {
    display: flex;
    align-items: center;
}

.icon-label__text {
    font-size: 14px;
    color: $color--grey-five;
}

.icon-label__icon {
    margin-right: 7px;
    width: 22px;
    height: 22px;
    flex-shrink: 0;

    & > svg {
        width: 100%;
        height: 100%;
    }
}
