html {
    font-size: (16px / 16px) * 100%;
    line-height: (25px / 16px);
    font-family: $font--body;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    padding-bottom: 3px;
    font-size: 14px;
    line-height: 18px;
    color: $color--grey-five;
    word-break: break-word;
}
