@import '../../sass/abstracts/variables';

.sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: $color--white;
    border-right: 1px solid $color--grey-four;
}

.sidebar__site-header {
    position: relative;
}

.sidebar__tabs {
    background: $color--grey-four;
    border-top: 1px dashed $color--grey-three;
}

.sidebar__tabs-panel {
    background: $color--white;
    // Account for padding
    max-height: calc(100% - 48px);

    > div {
        display: flex;
        flex-direction: column;
    }
}

.sidebar__body {
    width: 100%;
    height: 100%;
    border-top: 1px dashed $color--grey-three;
    padding-top: 0;

    @media only screen and ($device-desktop) {
        padding-top: $site-header-height;
    }
}

.sidebar__filters {
    padding: 20px;
    margin: -20px -20px 0;
    background-color: $color--grey-four;
}

.sidebar__options {
    padding: 20px;
    margin: 0 -20px;
    background-color: $color--white;
    border-top: 1px dashed $color--grey-three;
}

.sidebar__options-row {
    & + & {
        margin-top: 27px;
    }
}

.sidebar__fixed-button {
    bottom: -20px;
    position: sticky;
    margin: auto -20px -20px;
    padding: 20px;
    background-color: $color--white;
    border-top: 1px dashed $color--grey-three;
    z-index: 1;

    &--map-settings {
        padding: 0;
        border-top: none;
    }
}

.sidebar__options-buttons {
    display: flex;
    height: 100px;
    z-index: 3;
    opacity: 0.8;
}

.sidebar__options-button {
    & + & {
        margin-top: 10px;
    }
}

.sidebar__location {
    margin-bottom: 20px;
}

.sidebar__results-list {
    background-color: $color--grey-four;
    flex: 1;
    margin: -20px;
    padding: 20px;
}

.sidebar__summary {
    padding: 20px 20px 0;
    margin: -20px -20px 0;
    background-color: $color--grey-four;
}

.sidebar__checkbox-select {
    margin-top: 10px;
    z-index: 1;
}

.sidebar__overlay-groups {
    padding: 20px;
    margin: 0 -20px;
    background-color: $color--grey-four;
    border-top: 1px dashed $color--grey-three;
}
