@import '../../sass/abstracts/variables';

.location {
    position: relative;
    padding-right: 50px;
    display: flex;
    align-items: center;
}

.location__icon {
    flex-shrink: 0;
    height: 25px;
    margin-right: 10px;
}

.location__text {
    word-break: break-word;
    hyphens: auto;
}

.location__label {
    display: block;
    font-size: 16px;
    font-weight: $font-weight--bold;
    line-height: 19px;
    margin-bottom: 2px;
}

.location__distance {
    display: block;
    font-size: 13px;
}

.location__edit {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 14px;
    height: 14px;
    transform: translateY(-7px);

    &:focus,
    &:hover {
        opacity: 0.6;
    }

    svg {
        width: 100%;
        height: 100%;
    }
}
