@import '../../sass/abstracts/variables';

.list-cta {
    position: relative;
    width: 100%;
    padding: 12px 0;
    font-family: $font--body;
    font-size: 14px;
    text-align: left;
    color: $color--green;
    border: 0;
    background: transparent;
    overflow: hidden;

    &:hover,
    &:focus {
        color: darken($color--green, 20%);
        outline: none;
    }

    &--chevron {
        padding-right: 30px;
    }
}

.list-cta__chevron {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 12px;
    height: 12px;
    transform: translateY(-6px);
    transition: transform 0.2s;

    .list-cta:hover &,
    .list-cta:focus & {
        transform: translate(3px, -6px);
    }
}
