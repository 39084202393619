*,
*::before,
*::after {
    box-sizing: border-box;
}

body,
html,
#root {
    width: 100%;
    height: 100%;
    // overflow: hidden;
}

button {
    cursor: pointer;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}
