@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loading {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
}

.loading__icon {
    position: absolute;
    display: block;
    font-size: 22px;
    animation: rotate 1s infinite ease-in-out;
}
