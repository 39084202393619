.edit-button {
    width: 14px;
    height: 14px;
    margin-left: 10px;
    padding: 0;
    border: none;
    background-color: transparent;

    &:focus,
    &:hover {
        opacity: 0.6;
    }
}

.edit-button__icon {
    width: 100%;
    height: 100%;
}
