@import '../../sass/abstracts/variables';

.filter-card {
    position: relative;
    margin-top: 4px;
}

.filter-card__label {
    display: block;
    font-size: 13px;
    line-height: 16px;
}

.filter-card__value {
    display: block;
    font-size: 12px;
    line-height: 15px;
    font-weight: $font-weight--light;
}

.filter-card__edit-button {
    width: 100%;
    padding: 11px;
    font-family: $font--body;
    text-align: left;
    border: 1px solid $color--grey-three;
    background-color: $color--grey-four;
    border-radius: 3px;

    &:hover,
    &:focus {
        border-color: $color--grey-six;
    }

    & + & {
        margin-top: 5px;
    }
}

.filter-card__delete-button {
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -10px;
}
