@import '../../sass/abstracts/variables';

.summary {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 20px;

    @media only screen and ($device-tablet) {
        font-size: 17px;
        line-height: 27px;
    }

    strong {
        font-weight: $font-weight--bold;
    }
}

.summary__inner {
    padding-bottom: 15px;
    border-bottom: 1px dotted $color--grey-three;
}

.summary__box {
    margin-bottom: 15px;
    border-bottom: 1px dotted $color--grey-three;
    padding: 11px;
    background: $color--white;
    border: 1px solid $color--grey-two;
    color: $color--grey-five;
    border-radius: 3px;

    p {
        padding-bottom: 3px;
        font-size: 14px;
        line-height: 18px;
        border-bottom: 1px dashed lightgrey;
        word-break: break-word;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }
    }
}

.summary__box-label {
    display: inline-block;
    font-weight: 600;
    padding-left: 10px;
    width: 140px;
    white-space: nowrap;

    @media only screen and ($device-mobile--small) {
        width: 170px;
    }
    @media only screen and (min-width: $sidebar-width) {
        width: 190px;
    }

    & ~ & {
        font-weight: 400;
        color: black;
        padding-left: 30px;
        font-size: 13px;
        margin: 2px 0;
    }
}
.summary__box-value {
    display: inline-block;
    font-weight: 400;
    vertical-align: middle;
    width: 109px; // 110px breaks the alignment on Windows (in any browser)
    padding-left: 10px;
    text-align: right;
    white-space: nowrap;
    font-size: 12.5px;
    // make it monospace: https://github.com/JulietaUla/Montserrat/issues/101#issuecomment-579706104
    font-variant-numeric: tabular-nums;
    -moz-font-feature-settings: 'tnum';
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    letter-spacing: -0.3px;
    color: #2a2a2a; // monospace is a bit too light, so make it darker to appear bolder
    &.currency {
        // make the currency symbol stand out a bit:
        &::first-letter {
            letter-spacing: 1.5px;
            font-family: sans-serif;
            color: #666;
        }
    }
}

.summary__heading {
    margin-bottom: 12px;
    font-family: $font--heading;
    font-size: 20px;
    color: $color--green-two;
    text-transform: uppercase;
    display: inline-block;
}
