// These styles are also imported for all FilterValues* components
@import '../../sass/abstracts/variables';

.input-select {
    position: relative;
    @media only screen and ($device-tablet) {
        min-width: 160px;
    }
}

.input-select__search {
    padding-left: 40px !important;
    position: relative;

    &__icon {
        left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &__placeholder {
        font-size: 11px !important;
        height: 11px;
        color: #1E272B !important;
        white-space: nowrap;
        max-width: 80%;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: $font--body;
        letter-spacing: 0.5px;

        @media only screen and ($device-tablet) {
            font-size: 14px !important;
            height: 15px;
        }
    }
}

.input-select--native {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
    opacity: 0;
}
