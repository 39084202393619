@import '../../sass/abstracts/variables';

.input-text {
    width: 100%;
    padding: 12px;
    font-size: 13px;
    border: 1px solid $color--grey-three;
    border-radius: 3px;
    background: $color--white;
    color: $color--grey;
    transition: border 0.2s;

    &:hover,
    &:focus {
        border-color: $color--grey;
    }

    &::placeholder {
        color: $color--grey-three;
    }
}

.input-text__help {
    display: block;
    margin-top: 10px;
    font-size: 13px;
    font-weight: $font-weight--light;
}
