@import '../../sass/abstracts/variables';

.close {
    position: absolute;
    top: 0;
    right: 0;
    width: 130px;
    height: 105px;
    overflow: hidden;
    border-radius: 0 3px 0 0;
}

.close__button {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: 0;
    z-index: 1;
    cursor: pointer;

    &:hover,
    &:focus {
        .close__cross {
            fill: $color--grey;
        }
    }
}

.close__cross {
    fill: $color--green;

    @media only screen and ($device-desktop) {
        fill: $color--white;
    }
}

.close__bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 129px;
    height: 104px;
    display: none;

    @media only screen and ($device-desktop) {
        display: block;
    }
}
