@import '../../sass/abstracts/variables';

.layout {
    display: flex;
    width: 100%;

    // the expanded menu should appear on top of this
    position: absolute;
    z-index: 3;

    &--browse {
        padding-top: $site-header-height;
        background-image: url('/images/bg.svg');
        background-size: cover;
        min-height: var(--viewport-height, 100vh);
        overflow-x: hidden;

        @media only screen and ($device-desktop) {
            min-height: 100vh;
            min-height: var(--viewport-height, 100vh);
            padding-top: $site-header-height;
        }

        .layout__right-panel {
            display: flex;
            align-self: center;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }

    &--map,
    &--site {
        height: 100vh;
        height: var(--viewport-height, 100vh);
        padding-top: $site-header-height; // Account for site header

        @media only screen and ($device-desktop) {
            min-height: 100vh;
            min-height: var(--viewport-height, 100vh);
            padding-top: 0;
        }

        .layout__left-panel {
            position: absolute;
            height: calc(100vh - #{$site-header-height});
            height: calc(
                var(--viewport-height, 100vh) - #{$site-header-height}
            );
            z-index: 3;

            @media only screen and ($device-desktop) {
                position: relative;
                height: 100%;
            }
        }

        .layout__right-panel {
            display: flex;
            flex-direction: column;
            position: absolute;
            width: 100%;
            height: calc(100vh - #{$site-header-height});
            height: calc(
                var(--viewport-height, 100vh) - #{$site-header-height}
            );
            overflow: auto;

            @media only screen and ($device-desktop) {
                position: relative;
                height: 100%;
                width: calc(100% - 380px);
                flex: 1;
            }
        }
    }

    &--map {
        .layout__left-panel {
            @media only screen and ($device-desktop) {
                width: 380px;
            }
        }
    }

    &--site-selector {
        padding-top: 0;
        .layout__left-panel {
            display: none;
        }
        .layout__right-panel {
            height: 100vh;
            height: var(--viewport-height, 100vh);
        }
    }
}
