@import '../../sass/abstracts/variables';

.saved-searches {
    width: 100%;
    padding: 30px;
    padding-top: 100px;
    height: 100%;
    background: $color--grey-eight;
}

.saved-searches__item {
    margin-bottom: 10px;
}
