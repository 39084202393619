@import '../../sass/abstracts/variables';

.button {
    padding: 10px 20px;
    font-size: 15px;
    line-height: 19px;
    font-family: $font--body;
    color: $color--green;
    border: 0;
    background-color: transparent;

    &:hover,
    &:focus {
        color: darken($color--green, 10%);
    }

    &--bold {
        font-weight: 600;
    }

    &--cancel {
        color: $color--red;

        &:hover,
        &:focus {
            color: darken($color--red, 10%);
        }
    }

    // The primary button style
    // This was called "rounded" for historical reasons
    &--rounded {
        background-color: $color--green-four;
        color: $color--grey;
        font-family: $font--heading;
        text-transform: uppercase;
        transition-duration: 0.3s;
        transition-property: background-color, color, transform, opacity;
        transition-timing-function: ease;

        &:hover,
        &:focus {
            color: $color--grey;
            background-color: darken($color--green-four, 3%);
            transform: translate3d(0, -5%, 0);
        }

        &:disabled {
            background-color: $color--grey-two;
            cursor: default;
        }
    }
}
