@import '../../sass/abstracts/variables';

.delete-filter-button {
    height: 20px;
    background: none;
    border: 0;
    padding: 0;

    &:hover,
    &:focus {
        .delete-filter-button__icon {
            fill: $color--grey-seven;
        }
    }

    &--inverse {
        &:hover,
        &:focus {
            .delete-filter-button__icon {
                fill: $color--grey-three;
            }
        }
    }
}

.delete-filter-button__icon {
    height: 20px;
    fill: $color--grey-three;
    transition: 200ms;

    &--inverse {
        fill: $color--grey-seven;
    }
}
