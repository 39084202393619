@import '../../sass/abstracts/variables';

.map {
    width: 100%;
    height: 100%;
}

.map__site-popup-card {
    border: none;
    margin: -13px -11px -11px;
}

.map__site-popup-cta {
    margin-top: 11px;
    font-weight: $font-weight--bold;
    font-family: $font--body;
    font-size: 15px;
    padding: 10px 20px;
    color: $color--white;
    background-color: $color--green-two;
    border-radius: 3px;
    transition: background-color 0.1s;
    text-align: center;
    text-decoration: none;
    display: inline-block;

    &:hover,
    &:focus {
        color: $color--white;
        background-color: darken($color--green-two, 5%);
    }

    &.siteSelector {
        color: $color--green-two;
        background-color: $color--white;
        border: 0;
        padding: 5px 10px;
        width: 45%;
        & + button:not(:last-child) {
            float: right;
            color: $color--white;
            background-color: $color--green-two;
        }
    }
}

.map__key {
    bottom: 25px;
    max-width: calc(100vw - 20px); // Prevent overflow the viewport on mobile
    min-width: 160px;
    position: fixed;
    right: 10px;
    z-index: 1;

    @media only screen and ($device-tablet) {
        bottom: 30px;
        max-width: 240px;
        right: 30px;
    }
}

.map__global-site-options {
    position: absolute;
    z-index: 1; // To stack infront of map view
    background: white;
    padding: 10px;
    padding-right: 0;
    width: 120px;
    bottom: 25px;
    left: 10px;

    @media only screen and ($device-mobile--small) {
        width: 170px;
    }

    @media only screen and ($device-tablet) {
        min-width: 195px;
        bottom: 30px;
        left: 30px;
    }
}

.map__site-information {
    position: relative;
    height: 0%;
    width: 100%;
    z-index: 2;
    flex-shrink: 0;
    margin-top: -1px;
}

.map__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color--white, 0.5);
    z-index: 10;
}

.drop-container {
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(100, 100, 100, 0.5);
}
.drop-silhouette {
    color: white;
    border: white dashed 8px;
    height: 100%;
    width: 100%;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAAXNSR0IArs4c6QAAAAZiS0dEAGQAZABkkPCsTwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB90LHAIvICWdsKwAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAACdklEQVR42u3csU7icBzA8Xp3GBMSeRITH8JHMY7cRMvmVmXoE9TAcJubhjD4ApoiopgqDMWAKAgIcSAiCfxuwhwROVJbkPD9rP23ob8vpZCQKgoAAAAAAAAAAPDYyiK/eNM05bNtr6+vSjgcXiHxDMkE1WpVFvGcfpCVICAIQUAQgoAgBAFBCAKCgCAEAUEIAoIQBAQhCAgCghAEBCEICEIQEIQgIAgIQhAQhCAgCEFAEIKAICAIQUAQgoAgBAFBCDIzhmFINBo9/K6D0XVddnd3ZaneDY7jSCqVcn3SfjyeKRKJbJ2dnYllWbKUl2i5XJaXlxdJJBIy7yDHx8fy9vYm6XR6OWMM3d/fi4hIqVSSWCwmsw5ycHAgrVZLRETOz8+XO8ZQpVJ5H2Y6nRZN0/b9DqLruhSLxfd9MpkMMT6L0uv1JJlMih9BhveJwWDwvv7i4oIY4zw8PIwMtt1uSzweF6+CHB0dSbfbHVmbzWaJMcnj4+OHAd/d3cne3p64DWKapjw/P39Yd3l5SYxpVKvVsYO2LEtUVd2ZNoiu6+I4ztg1V1dXxPAiSq/Xk5OTk0k9pNVqyenp6ch94l+5XI4YbtRqNfHa9fX1t43xcwGa/Nnc3PwdDAY9OZht28rGxgZPvP6KSCSy9fT09OUrw7ZtPqa8jFKv113HuLm5IYbXVFXdcRPl9vaWGH5GaTQaU8fI5/PE8JumafvNZvO/MQqFAjFmJRqNHk6Ksqgx5vr1zzAM2d7edr3/6uqqsra2NnZbp9NR+v2+62OHQqG5zObXPIMEAgFlfX3dl2N79btl1viTA0FAEIKAIAQBAAAAAAAAsMz+Ai1bUgo6ebm8AAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: center;
}
