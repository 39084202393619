@import '../../sass/abstracts/variables';

/**
 * Full width tabs (default)
 */

.tabs {
    height: 100%;
}

.tabs__list {
    // display: flex; // TODO: uncomment and fix facilities tabs on site detail page
    padding: 5px 20px 0;
}

.tabs__tab {
    display: inline-block;
    position: relative;
    bottom: -1px;
    list-style: none;
    margin: 0 1px;
    font-size: 13px;
    color: $color--green;
    border-width: 1px;
    border-style: solid;
    border-color: $color--grey-two;
    border-bottom-color: $color--grey-three;
    border-radius: 3px 3px 0 0;
    background-color: $color--white;
    transition: all 0.2s;
    cursor: pointer;
    padding: 13px 10px;

    @media only screen and ($device-mobile--medium) {
        padding: 13px 19px;
    }

    &:hover {
        color: $color--grey;

        &[class*='--selected'] {
            background-color: $color--grey-four;
        }
    }

    &[class*='--selected'] {
        color: $color--grey;
        border-color: $color--grey-three;
        border-bottom-color: $color--grey-four;
        background-color: $color--grey-four;
    }

    &[class*='--disabled'] {
        color: $color--grey-three;
        background-color: $color--grey-four;
        cursor: default;
    }

    &:focus {
        background-color: lighten($color--grey-four, 5%);

        &[class*='--selected'] {
            background-color: $color--grey-four;
        }
    }
}

.tabs__panel {
    display: none;
    height: 100%;
    padding: 20px;
    overflow: auto;
    border-color: $color--grey-three;
    border-style: solid;
    border-width: 1px 0 0 0;
    background-color: $color--grey-four;

    &[class*='--selected'] {
        display: block;
    }

    & > div {
        height: 100%;
    }
}

/**
 * Variations
 * --align-left: Tab list aligned left
 * --light:  Light themed tabs
 */

.tabs--allow-overflow {
    .tabs__panel {
        overflow: initial;
    }
}

.tabs--align-left,
.tabs--light {
    .tabs__tab {
        bottom: -1px;
        border-width: 1px 1px 1px 1px;
    }

    .tabs__panel {
        border-width: 1px 1px 1px 1px;
    }
}

.tabs--align-left {
    padding: 20px 0;

    .tabs__list {
        padding-left: 0;
        margin-left: -1px;
    }

    .tabs__panel {
        border-radius: 0 3px 3px 3px;
    }
}

/**
 * Light theme tabs
 */

.tabs--light {
    .tabs__tab {
        border-color: $color--grey-two;
        border-bottom-color: $color--grey-three;
        background-color: $color--white;
    }

    .tabs__tab[class*='--selected'] {
        border-color: $color--grey-three;
        border-bottom-color: $color--white;
        background-color: $color--white;
    }

    .tabs__panel {
        border-top-color: $color--grey-three;
        background-color: $color--white;
    }

    &.tabs--align-left .tabs__panel {
        border-color: $color--grey-three;
    }
}

/**
 * Stacked tabs
 */

.tabs--stacked {
    // .tabs__tab {}.tabs__tab[class*='--selected'] {}.tabs__panel {}
    display: flex;
    padding: 0;
    border-top: 1px $color--grey-three solid;

    .tabs__tab {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.2;
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-bottom: 1px dashed $color--grey-three;
        background-color: $color--grey-four;
        transition: none;

        @media only screen and ($device-mobile--medium) {
            padding: 10px;
        }

        &:first-child {
            margin-top: -3px;
        }
    }

    .tabs__tab[class*='--selected'] {
        border-style: solid;
        border: 1px solid $color--grey-three;
        border-right-color: $color--white;
        background-color: $color--white;
        border-left: none;
        margin-top: -1px;
        padding-top: 13px;

        &:first-child {
            margin-top: -3px;
            padding-top: 12px;
        }
    }

    .tabs__list {
        padding: 0;
        padding-bottom: 40px;
        border-right: 1px solid $color--grey-three;
        width: 140px;
        min-width: 140px;

        @media only screen and ($device-mobile--medium) {
            width: 200px;
            min-width: 200px;
        }
    }

    .tabs__panel {
        border-radius: 0;
        border: none;
        background-color: white;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0 18px 18px 18px;

        @media only screen and ($device-mobile--medium) {
            margin: 30px;
        }
    }
}
