@import '../../sass/abstracts/variables';

.form-step {
    position: relative;
    width: 100%;
    padding: $modal-padding--mobile;
    background-color: $color--white;
    border-radius: 3px;

    p {
        font-size: 14px;
    }

    @media only screen and ($device-tablet) {
        padding: $modal-padding--tablet / 2 $modal-padding--tablet;

        p {
            font-size: 16px;
        }
    }
}

// Class to modify heading when inside a form step
.form-step__heading {
    padding-right: 100px;
}

// Class to modify close when in a form step
.form-step__close {
    position: absolute;
    top: 0;
    right: 0;
}
