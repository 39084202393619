@import '../../sass/abstracts/variables';

.save-search {
    position: relative;

    @media only screen and ($device-tablet) {
        width: 560px;
    }

    p {
        margin-bottom: 20px;
        line-height: 1.6;
    }
}

.save-search__description {
    @media only screen and ($device-tablet) {
        max-width: 400px;
    }
}

.save-search__row--full {
    background: $color--grey-four;
    padding: 30px $modal-padding--mobile;
    margin: 0 (-$modal-padding--mobile) 30px;

    @media only screen and ($device-tablet) {
        padding: 30px $modal-padding--tablet;
        margin: 0 (-$modal-padding--tablet) 30px;
    }
}
