@import '../../sass/abstracts/variables';

.filter-groups--empty {
    border-top: 1px dashed $color--grey-three;
}

.filter-group {
    position: relative;
    margin: 0 0 10px 0;
    padding: 15px;
    background-color: $color--white;
    border: 1px solid $color--grey-two;
    border-radius: 3px;
}

.filter-group__label {
    display: block;
    margin: 0 0 11px;
    width: calc(100% - 40px);
    font-size: 15px;
}

.filter-group__edit {
    height: 26px;
    padding: 6px;
    position: absolute;
    right: 15px;
    top: 9px;
    width: 26px;
}

.filter-group__delete {
    position: absolute;
    top: 12px;
    right: 10px;
}

.filter-group__circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 5px;
}
