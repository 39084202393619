@import '../../sass/abstracts/variables';

.site-card {
    position: relative;
    padding: 11px;
    background: $color--white;
    border: 1px solid $color--grey-two;
    color: $color--grey-five;
    border-radius: 3px;

    &--clickable {
        &:hover,
        &:focus {
            cursor: pointer;
            border: 1px solid $color--grey-three;
        }
    }
}

.site-card__header {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.site-card__name {
    display: block;
    margin-bottom: 3px;
    font-size: 14px;
    width: calc(100% - 20px);
    line-height: 18px;
    color: $color--green-two;
    font-weight: $font-weight--bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .site-card--non-operational & {
        padding-left: 20px;
    }
}

.site-card__address {
    font-size: 11px;
    line-height: 14px;
}

.site-card__funded {
    position: absolute;
    right: 8px;
    bottom: 6px;

    &:hover {
        .site-card__funded_label {
            opacity: 1;
        }
    }
}

.site-card__funded_logo {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 30px;
    height: 30px;
}

.site-card__funded_label {
    display: none; // disabled for now, but we might want to add back somehting similar later
    position: absolute;
    left: -100px;
    bottom: 0;
    padding: 7px 3px;
    margin-left: 10px;
    opacity: 0;
    transition: opacity ease 0.1s;
    background: #5ab947;
    color: white;
    font-size: 9px;
    font-weight: 600;
    text-align: center;
}

.site-card__icon {
    position: absolute;
    top: 1px;
    width: 15px;
    height: 15px;
    fill: $color--red;
}

.site-card__details {
    padding: 11px;
    background-color: $color--grey-four;
}

.site-card__facilites {
    display: flex;
    flex-wrap: wrap;
}

.site-card__stats {
    font-size: 11px;

    &--dark-theme {
        color: $color--grey-three;
    }
}

.site-card--dark-theme {
    background-color: $color--grey;
    border-color: $color--grey-five;

    .site-card__name {
        color: $color--white;
    }

    .site-card__details {
        background-color: $color--black;
    }

    .site-card__address {
        color: $color--grey-three;
    }
}
