@import '../../sass/abstracts/variables';

/**
 * Custom Popup
 * https://developers.google.com/maps/documentation/javascript/examples/overlay-popup
 */

$tip-height: 15px;
$tip-width: 20px;
$bg-color: $color--grey;

.map-popup {
    cursor: auto;
    height: 0;
    position: absolute;
    width: 100%;
    max-width: 320px;
    font-family: $font--body;
}

.map-popup__inner {
    /* Position the div a fixed distance above the tip. */
    position: absolute;
    width: 100%;
    bottom: $tip-height;
    left: 0;
}

.map-popup__inner::after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: $tip-width / 2 solid transparent;
    border-right: $tip-width / 2 solid transparent;
    border-top: $tip-height solid $bg-color;
}

.map-popup__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 18px;
    transform: translate(-50%, -100%);
    background-color: $bg-color;
    border-radius: 3px;
    overflow-y: auto;
}

.map-popup__close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 22px;
    height: 22px;
    border: none;
    background: transparent;

    &:hover {
        .map-popup__close-icon {
            fill: $color--green;
        }
    }
}

.map-popup__close-icon {
    position: absolute;
    top: 0;
    left: 0;
    fill: $color--green-two;
    width: 100%;
    height: 100%;
}
