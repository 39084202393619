@import '../../sass/abstracts/variables';

.checkbox-select {
    position: relative;
    height: 23px;
}

.checkbox-select__button {
    position: absolute;
    top: 0;
    left: 0px;
    padding: 0;
    border: none;
    background: transparent;
}

.checkbox-select__button-icon {
    width: 20px;
    height: 20px;
    fill: $color--green-two;
    box-shadow: inset 0 0 0 1px $color--grey-three;
    border-radius: 3px;

    &:focus,
    &:hover {
        box-shadow: inset 0 0 0 1px $color--grey-seven;
    }
}

.checkbox-select__select {
    margin-left: 30px;
    transform: translateY(-4px);
}
