/**
 * Styles "ported" from legacy PitchFinder
 */

.map-control {
    float: left;
    line-height: 0;
    & > div {
        direction: ltr;
        overflow: hidden;
        text-align: left;
        position: relative;
        color: rgb(86, 86, 86);
        font-family: Roboto, Arial, sans-serif;
        -webkit-user-select: none;
        font-size: 11px;
        padding: 4px;
        border-radius: 2px;
        -webkit-background-clip: padding-box;
        box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
        border-left-width: 0;
        background-color: rgb(255, 255, 255);
        background-clip: padding-box;
        &:hover {
            cursor: default;
            background-color: rgb(235, 235, 235);
        }
        & *:hover {
            cursor: default;
        }
        & > span {
            display: inline-block;
            & > div {
                width: 16px;
                height: 16px;
                overflow: hidden;
                position: relative;
                & > img {
                    position: absolute;
                    left: 0;
                    top: -64px;
                    -webkit-user-select: none;
                    border: 0;
                    padding: 0;
                    margin: 0;
                    max-width: none;
                    width: 16px;
                    height: 192px;
                }
            }
        }
    }
}

.map-control.remove-polygons {
    margin-top: 5px;
    margin-left: -8px;
    & > div {
        padding-left: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        & > span {
            & > div {
                color: #888;
                font-weight: bold;
                font-size: 18px;
                line-height: 14px;
                vertical-align: middle;
                text-align: center;
                & > img {
                    visibility: hidden;
                }
            }
        }
    }
}

#map-tooltip {
    display: none;
    position: fixed;
    z-index: 0;
    padding: 3px;
    font-size: 15px;
    background-color: white;
    box-shadow: 0 0 3px black;
    border-radius: 3px;
    line-height: 1.4;
}
