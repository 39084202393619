@font-face {
    font-family: 'Barlow Condensed';
    font-display: swap;
    src: url('../../fonts/barlow/BarlowCondensed-SemiBold.eot');
    src: url('../../fonts/barlow/BarlowCondensed-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/barlow/BarlowCondensed-SemiBold.woff') format('woff'),
    url('../../fonts/barlow/BarlowCondensed-SemiBold.woff2') format('woff2'),
    url('../../fonts/barlow/BarlowCondensed-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    src: url('../../fonts/montserrat/Montserrat-Regular.eot');
    src: url('../../fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
    url('../../fonts/montserrat/Montserrat-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    src: url('../../fonts/montserrat/Montserrat-SemiBold.eot');
    src: url('../../fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
    url('../../fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    src: url('../../fonts/montserrat/Montserrat-Light.eot');
    src: url('../../fonts/montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/montserrat/Montserrat-Light.woff') format('woff'),
    url('../../fonts/montserrat/Montserrat-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
