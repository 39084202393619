@import '../../sass/abstracts/variables';

.site-search__links-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    @media only screen and ($device-mobile--medium) {
        flex-direction: row;
    }
}

.site-search__browse-map {
    font-size: 14px;
    background-color: white;
}

.site-search__current-location {
    background-color: white;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 20px;

    span {
        color: $color--green;
        &:hover {
            text-decoration: underline;
        }
    }

    @media only screen and ($device-mobile--medium) {
        padding: 0;
    }
}

.site-search-meta__search_region {
    max-width: 390px;
}

.site-search-meta__row--container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    @media only screen and ($device-mobile--medium) {
        flex-direction: row;
    }
}

.site-search-meta__input {
    display: flex;
    margin-bottom: 10px;
    @media only screen and ($device-mobile--medium) {
        margin: 0;
    }
}

.site-search-meta__input_journey-time {
    width: 80px;
    margin-bottom: 10px;
}

.site-search-meta__text_journey {
    padding: 12px;
    display: flex;
    align-items: center;
    font-size: 13px;
}

.site-search-meta__select-transport {
    min-width: 220px;
}
