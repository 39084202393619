@import '../../sass/abstracts/variables';

.bar {
    padding: 11px 0 20px;
    border-bottom: 1px dashed $color--grey-two;
}

.bar__heading {
    margin: 0 0 11px;
}

.bar__row {
    display: flex;
    font-family: $font--body;
    font-size: 28px;
    line-height: 32px;
    font-weight: $font-weight--light;

    @media only screen and (min-width: 768px) {
        font-size: 32px;
        line-height: 39px;
    }
}

.bar__block {
    background: $color--grey-two;
    height: 12px;
    flex-grow: 1;
    margin-right: 8px;

    &:first-child {
        margin-left: 2px;
    }

    &:last-child {
        margin-right: 10px;
    }
}
.bar__fill {
    height: 100%;
    width: 100%;
    background: $color--green-two;
}
