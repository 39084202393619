@import '../../sass/abstracts/variables';

.filter-group-meta {
    width: 100%;

    @media only screen and ($device-tablet) {
        display: flex;
    }
}

.filter-group-meta__column-text {
    width: 100%;
    margin-bottom: 20px;

    @media only screen and ($device-tablet) {
        margin-bottom: 0;
    }
}

.filter-group-meta__column-select {
    @media only screen and ($device-tablet) {
        margin-left: 5px;
        min-width: 220px;
    }
}

.filter-group-form__column-heading {
    display: inline-block;
}

.filter-group-meta__input-group {
    position: relative;
}

.filter-group-meta__input {
    padding-left: 16px;
}
